export enum Roles {
  SPEAKER = 'speaker',
  JUDGE = 'judge',
  MENTOR = 'mentor',
  ALL = 'all'
}

export const people = [
  {
    name: "Sunny Aggarwal",
    image: "Sunny Aggarwal.jpg",
    roles: [Roles.SPEAKER],
    company: 'Osmosis Labs',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Harsh Rajat",
    image: "Harsh Rajat.jpg",
    roles: [Roles.SPEAKER],
    company: 'Ethereum Push Notification Service',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Julien Bouteloup",
    image: "Julien Bouteloup.jpg",
    roles: [Roles.SPEAKER],
    company: 'StakeDAO',
    twitterLink: '',
    isImageEdited: true
  },
  // {
  //   name: "Guillaume	Ballet",
  //   image: "",
  //   roles: [Roles.SPEAKER],
  //   company: 'Ethereum Foundation',
  //   twitterLink:, ''
  // isImageEdited: true  // 
  {
    name: "Franziska Heintel",
    image: "Franziska Heintel.jpg",
    roles: [Roles.SPEAKER],
    company: 'Ethereum Foundation',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Avihu Levy",
    image: "Avihu Levy.jpg",
    roles: [Roles.SPEAKER],
    company: 'StarkWare',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Austin Griffith",
    image: "Austin Griffith.jpg",
    roles: [Roles.SPEAKER],
    company: 'Ethereum Foundation',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Afri Schoedon",
    image: "Afri Schoedon.jpg",
    roles: [Roles.JUDGE],
    company: 'ChainSafe Systems',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Jaye Harrill",
    image: "Jaye Harrill.jpg",
    roles: [Roles.SPEAKER],
    company: 'Quantstamp',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Clément Lesaege",
    image: "Clément Lesaege.jpg",
    roles: [Roles.SPEAKER],
    company: 'Kleros',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Steven Waterhouse",
    image: "Steven Waterhouse.jpg",
    roles: [Roles.SPEAKER],
    company: 'Orchid Labs',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Zaki Manian",
    image: "Zaki Manian.jpg",
    roles: [Roles.SPEAKER],
    company: 'Sommelier',
    twitterLink: '',
    isImageEdited: true
  },
  {
    name: "Camila Ramos Garzon",
    image: "Camila Ramos Garzon.jpg",
    roles: [Roles.SPEAKER],
    company: 'STEMTank',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Anett Rolikova",
    image: "Anett Rolikova.jpg",
    roles: [Roles.JUDGE],
    company: 'Ethereum Magicians',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Tina Haibodi",
    image: "Tina Haibodi.jpg",
    roles: [Roles.SPEAKER],
    company: 'Element Finance',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Jarrad Hope",
    image: "Jarrad Hope.jpg",
    roles: [Roles.SPEAKER],
    company: 'Status',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Josef Je",
    image: "Josef Je.jpg",
    roles: [Roles.MENTOR],
    company: 'PWN',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Mario Havel",
    image: "Mario Havel.jpg",
    roles: [Roles.SPEAKER],
    company: 'Ethereum Foundation',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "William Rudolf Lobkowicz",
    image: "William Rudolf Lobkowicz.jpg",
    roles: [Roles.SPEAKER],
    company: 'House of Lobkowicz',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Guillaume Ballet",
    image: "Guillaume Ballet.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "David Mihal",
    image: "David Mihal.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Marius van der Wijden",
    image: "Marius van der Wijden.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Will Villanueva",
    image: "Will Villanueva.png",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Andrej Berlin",
    image: "Andrej Berlin.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Bengisu Berispek",
    image: "Bengisu Berispek.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Blendor Sefaj",
    image: "Blendor Sefaj.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Cash",
    image: "Cash.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Daniel Vaczi",
    image: "Daniel Vaczi.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Dankrad Feist",
    image: "Dankrad Feist.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Danny Holland",
    image: "Danny Holland.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "David Leonardi",
    image: "David Leonardi.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Diego Losada",
    image: "Diego Losada.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Dobroslawa Gogloza",
    image: "Dobroslawa Gogloza.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Dominik Teiml",
    image: "Dominik Teiml.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Emily Wigoder",
    image: "Emily Wigoder.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Fernando Collado",
    image: "Fernando Collado.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Jacek Sieka",
    image: "Jacek Sieka.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "James Zaki",
    image: "James Zaki.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Jamilya Kamalova",
    image: "Jamilya Kamalova.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Jarrell James",
    image: "Jarrell James.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Joe Schweitzer",
    image: "Joe Schweitzer.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "John Giamporcaro",
    image: "John Giamporcaro.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Juan Guillen",
    image: "Juan Guillen.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Justyna Broniszewska",
    image: "Justyna Broniszewska.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Karol Podufalski",
    image: "Karol Podufalski.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Laurence Kirk",
    image: "Laurence Kirk.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Liesl Eichholz",
    image: "Liesl Eichholz.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Lili Feyerabend",
    image: "Lili Feyerabend.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Lucas Manuel",
    image: "Lucas Manuel.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Makoto Inoue",
    image: "Makoto Inoue.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Manuel Gonzalez Alzuru",
    image: "Manuel Gonzalez Alzuru.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Marek Kirejczyk",
    image: "Marek Kirejczyk.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "María Paula Fernandez",
    image: "María Paula Fernandez.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Martin Baric",
    image: "Martin Baric.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Martin",
    image: "Martin.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Mary Pan",
    image: "Mary Pan.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Matteo Alessio Tambussi",
    image: "Matteo Alessio Tambussi.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Matthew Di Ferrante",
    image: "Matthew Di Ferrante.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Matthias Tarasiewicz",
    image: "Matthias Tarasiewicz.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Max Lomuscio",
    image: "Max Lomuscio.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Miao ZhiCheng",
    image: "Miao ZhiCheng.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Michael Herbig",
    image: "Michael Herbig.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Michal Prevratil",
    image: "Michal Prevratil.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Nader Dabit",
    image: "Nader Dabit.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Nazar Hussain",
    image: "Nazar Hussain.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Nic Munoz-McDonald",
    image: "Nic Munoz-McDonald.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Pedro Gomez",
    image: "Pedro Gomez.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Péter Szilágyi",
    image: "Péter Szilágyi.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Ravital Solomon",
    image: "Ravital Solomon.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Rick Weber",
    image: "Rick Weber.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Rodrigo Seira",
    image: "Rodrigo Seira.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Sam Gittis",
    image: "Sam Gittis.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Sara Polak",
    image: "Sara Polak.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Sasha Pitkevich",
    image: "Sasha Pitkevich.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Sasha Shilina",
    image: "Sasha Shilina.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Steve Fau",
    image: "Steve Fau.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Thomas Zeinzinger",
    image: "Thomas Zeinzinger.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Thor Kamphefner",
    image: "Thor Kamphefner.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Tom Zhao",
    image: "Tom Zhao.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Tree",
    image: "Tree.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Tux Pacific",
    image: "Tux Pacific.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Uri Kirstein",
    image: "Uri Kirstein.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Viktor Tron",
    image: "Viktor Tron.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Vojtěch Šimetka",
    image: "Vojtěch Šimetka.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Wesley van Heije",
    image: "Wesley van Heije.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  },
  {
    name: "Wlodek Gogloza",
    image: "Wlodek Gogloza.jpg",
    roles: [Roles.SPEAKER],
    company: '',
    twitterLink: '',
    isImageEdited: false
  }
]
<template>
  <div class="sleep-and-rest__block-content-text">
  <span>
    There is a variety of affordable hotels around the venue. We have partnered
    with some!
  </span>
  <br /><br />
  <h3 style="text-align: center">Hotel Belvedere</h3>
  <ul>
    <li>
      Discounted prices on single (76€/night) and double (81€/night) rooms
    </li>
    <li>Code name: <strong>The Future Is Solarpunk</strong></li>
    <li>Any stay between 9-14 June</li>
    <li>
      Book through: <a href="mailto:booking@hhotels.cz"> booking@hhotels.cz </a>
    </li>
    <li>
      <a href="https://www.hotelbelvedereprague.cz/en" target="_blank">
        www.hotelbelvedereprague.cz/en
      </a>
    </li>
    <li>5 tram stops from Paralelni Polis</li>
  </ul>
  <div style="text-align: center">
    <img class="sleep-and-rest__hotel-image" width="600" src="../../assets/belveder.jpg" />
    <!-- // <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d359.09155326514116!2d14.430525752119133!3d50.09913589200549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94c66d210f35%3A0xf964b173cd95c149!2sHotel%20Belvedere%20Prague!5e0!3m2!1scs!2sca!4v1653489307417!5m2!1scs!2sca" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>  -->
    <br /><br /><br /><br />
    <h3 style="text-align: center">Mama Shelter</h3>
  </div>
  <ul>
    <li>Discount: 15% of direct website</li>
    <li>Code name: <strong>Winding Tree</strong></li>
    <li>Any stay between 9-14 June</li>
    <li>
      Book through:
      <a href="mailto:prague@mamashelter.com">prague@mamashelter.com</a> /
      <a href="tel:420 225 117 861" title="Mama Shelter">420 225 117 861</a>
    </li>
    <li>
      <a href="https://mamashelter.com/prague/" target="_blank">
        mamashelter.com
      </a>
    </li>
    <li>4 tram stops from Paralelni Polis</li>
    <li>Thanks to our friends from Winding Tree</li>
  </ul>
  <div style="text-align: center">
    <img class="sleep-and-rest__hotel-image" width="600" src="../../assets/mama-shelter.webp" />
    <!-- // <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.1316515779886!2d14.431399584230874!3d50.102542730716934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94c8cf78bd83%3A0x5b2444d0a8ec298d!2sMama%20Shelter%20Praha!5e0!3m2!1scs!2sca!4v1653500007374!5m2!1scs!2sca" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>  -->
  </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>

.sleep-and-rest__hotel-image {
  max-width:600px;
  width: 100%;
  max-height: 500px;
  object-position: right bottom;
  object-fit: cover;
}

.sleep-and-rest__block-content-text {
  max-width: 1100px;
  margin: auto;
  font-size: 17px;
}

</style>
